import axios from "axios";

export default async function (text) {
    try {
        const endpoint = "https://api.together.xyz/v1/chat/completions";
        const res = await axios.post(endpoint, {
            model: "meta-llama/Meta-Llama-3-8B-Instruct-Lite",
            max_tokens: 512,
            temperature: 0.7,
            top_p: 0.7,
            top_k: 50,
            repetition_penalty: 1,
            stop: [
                "<|eot_id|>"
            ],
            messages: [
                {
                    role: "system",
                    content: "You are a language translator, you only reply with translated text, nothing else."
                },
                {
                    role: "user",
                    content: "Translate the following Chinese to English."
                },
                {
                    role: "assistant",
                    content: "Sure, please provide your Chinese text."
                },
                {
                    role: "user",
                    content: text
                }
            ]
        }, {
            headers: {
                Authorization: "Bearer fdc02b777b4fd3693db5f67e9fb41fe7f4d2d3be64e0aad7dc23e0e2536fd791"
            }
        });
        return res.data.choices[0].message.content;
    } catch (err) {
        return "";
    }
}